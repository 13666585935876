export default {
    financialAccrual: {
        zhCN: {
            number_order: '序号',
            financial_accrual_name: '财务计提名称',
            policy_name: '政策名称',
            policy_number: '政策号',
            date: '日期',
            total_volume: '总成交量',
            total_spend: '总支出',
            spend_per_car: '单车费用',
            create_time: '创建时间',
            operation: '操作',
            accrual_time: '计提时间',
            time_range_of_data_source: '数据源时间范围',
            private_t: 'Private',
            dealer_fleet: 'Dealer Fleet',
            motability: 'Motability',
            demo: 'Demo',
            trim: 'Trim',
            sum_of_sales: 'Sum of Sales',
            sum_of_subsidy: 'Sum of Subsidy/FDA',
            sum_of_vms: 'Sum of VMS',
            per_car_vms: 'Per car VMS',
            materialCode: '物料号',
            reg_status: '注册状态',
            vms: 'VMS',
            fda_subsidy: 'FDA/Subsidy',
            is_null: '数据未填写完整',
            pre_conditions: 'Pre-conditions:',
            achievement_rate: 'Achievement Rate',
            achievement_rate_tips:
                '请注意，如果有级别数量的变化，增加的级别将没有参考数据，所以请手动分配。',
            sales_volume_share: '销量份额',
            allocation_ratio: 'Allocation Ratio (for other catogary)',
            assume_penetration_rate: 'Assume Penetration Rate',
            financing_rate: 'Financing Rate',
            assume_penetration_rate_required:
                'Assume penertration rate and financing rate is required',
            total_total: 'Total',
            total_sales: 'Sales',
            total_fda: 'Subsidy/FDA',
            total_vms: 'VMS',
        },
        enUS: {
            number_order: 'No.',
            financial_accrual_name: 'Financial Accrual Name',
            policy_name: 'Policy Name',
            policy_number: 'Policy Number',
            date: 'Date',
            total_volume: 'Total Volume',
            total_spend: 'Total Spend',
            spend_per_car: 'Spend Per Car',
            create_time: 'Create Time',
            operation: 'Operation',
            accrual_time: 'Accrual Time',
            time_range_of_data_source: 'Time Range of Data Source',
            private_t: 'Private',
            dealer_fleet: 'Dealer Fleet',
            motability: 'Motability',
            demo: 'Demo',
            trim: 'Trim',
            sum_of_sales: 'Sum of Sales',
            sum_of_subsidy: 'Sum of Subsidy/FDA',
            sum_of_vms: 'Sum of VMS',
            per_car_vms: 'Per car VMS',
            materialCode: 'Material Code',
            reg_status: 'Reg Status',
            vms: 'VMS',
            fda_subsidy: 'FDA/Subsidy',
            is_null: 'The data was not filled in completely',
            pre_conditions: 'Pre-conditions:',
            achievement_rate: 'Achievement Rate',
            achievement_rate_tips:
                'Please note that if there is a change in the number of levels, the added levels will not have reference data, so please allocate them manually.',
            sales_volume_share: 'Sales Volume Share',
            allocation_ratio: 'Allocation Ratio (for other catogary)',
            assume_penetration_rate: 'Assume Penetration Rate',
            financing_rate: 'Financing Rate',
            assume_penetration_rate_required:
                'Assume penertration rate and financing rate is required',
            total_total: 'Total',
            total_sales: 'Sales',
            total_fda: 'Subsidy/FDA',
            total_vms: 'VMS',
        },
    },
    wholesalesList: {
        zhCN: {
            order_no: '订单号',
            order_status: '订单状态',
            credit_company: '信贷公司',
            order_net_price: '订单价格（不含税）',
            paint_price: '喷漆价格',
            delivery_Price: '交付价格',
            discount_amount: '折扣金额',
            total_net_price: '总格（不含税）',
            final_amount: '最终价格',
            currency: '货币',
            invoice_no: '发票号',
            invoice_status: '发票状态',
            invoice_date: '开票时间',
            invoice_cancel_date: '取消开票时间',
        },
        enUS: {
            order_no: 'Order No.',
            order_status: 'Order status',
            credit_company: 'Credit Company',
            order_net_price: 'Order Net Price',
            paint_price: 'Paint Price',
            delivery_Price: 'Delivery Price',
            discount_amount: 'Discount Amount',
            total_net_price: 'Total Net Price',
            final_amount: 'Final Amount',
            currency: 'Currency',
            invoice_no: 'Invoice#',
            invoice_status: 'Invoice Status',
            invoice_date: 'Invoice Date',
            invoice_cancel_date: 'Invoice Cancel Date',
        },
    },
};
