import type { NavDataOrigin } from '~/utils/router';
import { commonRequests } from '~/requests/common/common.request';
import { resHandler } from '~/utils/http/handler';
import { formatRoutes, routeMap } from '~/utils/router';
import { useAuthToken } from '~/utils/token';
import { useStorageLocale } from '~/utils/i18n';
import { useUserStore } from '~/stores/user';

export const useCommonStore = defineStore('common', () => {
    const { authToken } = useAuthToken();

    const { defaultLocale: locale } = useStorageLocale();

    const showMenu = ref(true);
    const hRouterKey = ref(false);

    const formattedRoutes = ref<NavDataOrigin[]>([]);
    const entriesRoutes = computed(() => {
        return toTreeArray(formattedRoutes.value);
    });

    const currentRouteKey = ref('');
    const cachedRouteList = ref<string[]>([]);
    const iframeList = computed(() =>
        cachedRouteList.value.filter((i) => {
            // return i.includes('ServiceCdc');
            return ['ServiceCdc', 'ServiceEpc', 'ServiceDiagnosis'].some((path) =>
                i.includes(path),
            );
        }),
    );
    console.log(iframeList.value);
    watch(
        iframeList.value,
        (val) => {
            console.log(val);
        },
        {
            deep: true,
        },
    );

    const setAuthToken = async (token: string) => {
        authToken.value = token;
    };

    const clearAuth = async () => {
        authToken.value = '';
    };

    const reFetchRoutes = async () => {
        const { userInfo, companyCountry } = useUserStore();
        const roleCode = userInfo && companyCountry && (companyCountry.length > 1) && companyCountry.find(val => val.companyId === userInfo.companyId)?.roleCode || '';
        // console.log(89533, roleCode);
        const response = await commonRequests.getRoutes(roleCode);
        await resHandler(response, {
            onSuccess: ({ res }) => {
                routeMap.clear();
                formattedRoutes.value = formatRoutes(res);
            },
        });
    };

    return {
        authToken,
        setAuthToken,
        formattedRoutes,
        entriesRoutes,
        locale,
        reFetchRoutes,
        currentRouteKey,
        cachedRouteList,
        clearAuth,
        showMenu,
        hRouterKey,
        iframeList,
    };
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useCommonStore, import.meta.hot));
