export default {
    dealer: {
        zhCN: {
            dealer_code: '经销商代码',
            dealer_name: '经销商名称',
            dealer_sap_no: '经销商SAP号',
            region_code: '区域代码',
            region_name: '区域名称',
            area: '区域',
            region: '区域',
            rom: '区域经理',
            rom_name: 'rom姓名',
            rom_email: 'rom邮箱',
            business_status: '营业状态',
            created_by: '创建人',
            created_time: '创建时间',
            updated_by: '修改人',
            updated_time: '修改时间',
            dealer_email: '经销商邮箱',
            dealer_level: '经销商等级',
            group: '经销商分组',
            contact_person: '联系人',
            contact_email: '联系人邮箱',
            calculation_mode: '计算模式',
            must_be_group: '请输入经销商分组',
            vendor_number: '供应商编码',
            terminatedDate: '终止日期',
            input_dealer_email: '请输入经销商邮箱',
            is_delivery_refund: '是否返还运费',
            customer_code: '客户代码',
        },
        enUS: {
            dealer_code: 'Dealer Code',
            dealer_name: 'Dealer Name',
            dealer_sap_no: 'Dealer DCS No.',
            area: 'Area',
            region_code: 'Region Code',
            region_name: 'Region Name',
            region: 'Region',
            rom: 'Rom',
            rom_name: 'Rom Name',
            rom_email: 'Rom Email',
            business_status: 'Business Status',
            created_by: 'Created By',
            created_time: 'Created Time',
            updated_by: 'Updated By',
            updated_time: 'Updated Time',
            dealer_email: 'Dealer email',
            dealer_level: 'Dealer Level',
            group: 'Group',
            contact_person: 'Contact Person',
            contact_email: 'Contact Email',
            calculation_mode: 'Calculation mode',
            must_be_group: 'Please input the Group',
            vendor_number: 'Vendor Number',
            terminatedDate: 'Terminated Date',
            input_dealer_email: 'Please Input Dealer Email',
            is_delivery_refund: 'Is Delivery Refund',
            customer_code: 'Customer Code',
        },
    },
    material: {
        zhCN: {
            vehicle_material_code: '物料号',
            material_description: '物料描述',
            series_code: '车系代码',
            series: '车系',
            engine: '引擎',
            gear: '变速箱',
            trim_version: '版本',
            model_year: '车型年款',
            interior: '内饰颜色',
            exterior_code: '外饰颜色',
            Colour: '颜色',
            paint_type: '涂料类型',
        },
        enUS: {
            vehicle_material_code: 'Vehicle Material Code',
            material_description: 'Material Description',
            series_code: 'Series Code',
            series: 'Series',
            engine: 'Engine',
            gear: 'Gear',
            trim_version: 'Trim Version',
            model_year: 'Model Year',
            interior: 'Interior Code',
            exterior_code: 'Exterior Code',
            Colour: 'Paint&Colour',
            paint_type: 'Paint Type',
        },
    },
    systemParameter: {
        zhCN: {
            rebate_parameter: '返利参数',
            type_code: '类型代码',
            type: '类型',
            parameter_value: '参数值',
            unit: '单位',
            desc: '描述',
            change_history: '日志',
            fields: '字段',
            original_value: '原值',
            changed_value: '更改值',
            operator: '操作人',
            update_time: '更新时间',
        },
        enUS: {
            rebate_parameter: 'Rebate parameter',
            type_code: 'Type Code',
            type: 'Type',
            parameter_value: 'Parameter value',
            unit: 'Unit',
            desc: 'Desc.',
            change_history: 'LOG',
            fields: 'Fields',
            original_value: 'Original value',
            changed_value: 'Changed value',
            operator: 'Operator',
            update_time: 'Update Time',
        },
    },
    modelCategory: {
        zhCN: {
            carCategory: '汽车大类',
            dcsModel: 'DCS中车型',
            companyId: '公司ID',
            countryId: '国家ID',
            createTime: '创建时间',
            createBy: '创建人',
        },
        enUS: {
            carCategory: 'Car Category',
            dcsModel: 'Dcs Model',
            companyId: 'Company Id',
            countryId: 'Country Id',
            createTime: 'Create Time',
            createBy: 'Operator',
        },
    },
};
