export default {
    review: {
        zhCN: {
            number_order: '序号',
            dealer_num: '经销商网络代码',
            dealer_name: '经销商名称',
            monthly: '月度',
            total_rebate_amount: '返利总金额',
            approver: '审批人',
            approve_time: '审批时间',
            approval_status: '审批状态',
            operation: '操作',
            time_limit: '时间限制',
        },
        enUS: {
            number_order: 'NO.',
            dealer_num: 'Dealer Num',
            dealer_name: 'Dealer Name',
            monthly: 'Monthly',
            total_rebate_amount: 'Total Rebate Amount',
            approver: 'Approver',
            approve_time: 'Approve Time',
            approval_status: 'Approval Status',
            operation: 'Operation',
            time_limit: 'Time Limit',
        },
    },
    salesOrder: {
        zhCN: {
            sales_order: '销售订单',
            dealer_code: '经销商代码',
            dealer_name: '经销商名称',
            order_type: '订单类型',
            vin: 'VIN',
            model: '车型',
            reg_date: '注册日期',
            reg: '注册号',
            receive_time: '发布时间',
            order_num: '订单号',
            price_term: '价格条款',
            order_date: '下单日期',
            order_status: '订单状态',
            approval_status: '审批状态',
            model_version: '车型/版本',
            colour: '颜色',
            reg_cancel_date: '注册取消日期',
            channel: '注册方式',
            payment_type: '支付类型',
            coupons_no: '券号',
            credit_company: '信贷公司',
            basic_price: '基础价格（不含税）',
            paint_price: '喷漆费用（不含税）',
            delivery_price: '运费（不含税）',
            total_price: '订单总金额（不含税）',
            mgmfs_invoice: '开票金额',
            currency: '币种',
            rom: '区域经理',
            orderNo: 'Sales Order #',
            sales_order_edit: '注册订单编辑',
            modify_time: '修改时间',
            modify_reason: '修改原因',
            sure_submit: '请确认提交！',
            modifier: '修改人',
            original_mgmfs_invoice: '旧开票金额',
            new_mgmfs_invoice: '新开票金额',
            approval_opinion: '审批意见',
            please_orderNo: '请填写订单号',
            please_vin: '请填写vin码',
            please_modifyOne: '未找到相应订单',
            please_opinion: '拒绝时请输入审批意见',
            approvalRemark: '拒绝原因',
            status: '状态',
            error_msg: '错误信息',
            batch_pass: '批量通过',
            please_batch: '请先勾选批量审核的订单',
            vehicleMaterialCode: '物料号',
            start_registrationDate: '注册开始日期',
            end_registrationDate: '注册结束日期',
            price_term_code: '价格条款',
            template_name: 'Delivery Claim Template.xlsx',
            pickUpListUpload: '自提清单导入',
            inconsistent_basic_price_download: '差异数据下载',
            basic_price_upload: '基准价格导入',
            inconsistent_offline_basic_price: 'Inconsistent Offline Basic Price',
            material_code: '物料号',
            reg_no: '注册号',
            eu_invoice_no: 'Invoice No.',
            eu_invoice_status: 'Invoice Status',
            eu_country_name: 'Country Name',
            eu_country_code: 'Country Code',
            eu_group: 'Group',
            eu_financial_company: 'Financial Company',
            eu_interior_price: 'Interior Price',
            eu_invoice_price: 'Invoice Price',
            eu_unit_price: 'Unit Price(Incl.VAT)',
            eu_cancel_date: 'Cancel Date',
            eu_gate_out_time: 'Gate Out Time',
            eu_transportation_costs: 'Transportation Costs',
            eu_delivery_time: 'Delivery Time',
            eu_related_retail_order: 'Related Retail Order',
            eu_related_wholesales_order: 'Related Wholesales Order',
            eu_fleet_type: 'Fleet Type',
            eu_order_active_date: 'Order Activated Date',
            eu_contract_date: 'Contract Date',
            eu_account_record_type: 'Related Account Record Type',
            eu_incentive_amount: 'Incentive Amount',
            reg_status: 'Reg Status',
        },
        enUS: {
            sales_order: 'Register Sales Order',
            dealer_code: 'Dealer Code',
            dealer_name: 'Dealer Name',
            order_type: 'Order Type',
            vin: 'VIN',
            model: 'Model',
            reg_date: 'Reg Date',
            reg: 'Reg',
            receive_time: 'Receive Time',
            order_num: 'Order No.',
            price_term: 'Price Term',
            order_date: 'Order Date',
            order_status: 'Order Status',
            approval_status: 'Approval Status',
            model_version: 'Model/Version',
            colour: 'Colour',
            reg_cancel_date: 'Reg Cancel Date',
            channel: 'Channel',
            payment_type: 'Payment Type',
            coupons_no: 'Coupons No.',
            credit_company: 'Credit Company',
            basic_price: 'Basic Price',
            paint_price: 'Paint Price',
            delivery_price: 'Delivery Price',
            total_price: 'Total Price',
            mgmfs_invoice: 'MGMFS Invoice',
            currency: 'Currency',
            rom: 'Rom',
            orderNo: 'Sales Order #',
            sales_order_edit: 'Register Sales Edit',
            modify_time: 'Modify Time',
            modify_reason: 'Modify Reason',
            sure_submit: 'Please confirm the submission!',
            modifier: 'Modifier',
            original_mgmfs_invoice: 'Original MGMFS Invoice',
            new_mgmfs_invoice: 'New MGMFS Invoice',
            approval_opinion: 'Approval Opinion',
            please_orderNo: 'Please input SalesOrder #',
            please_vin: 'Please input vin',
            please_modifyOne: 'No sales orders found',
            please_opinion: 'Please input the approval opinion when refusing',
            approvalRemark: 'Approval Remark',
            status: 'Status',
            error_msg: 'Error Message',
            batch_pass: 'Batch Pass',
            please_batch: 'Please check the order for batch audit first',
            vehicleMaterialCode: 'VMC',
            start_registrationDate: 'Registration start date',
            end_registrationDate: 'Registration end date',
            price_term_code: 'Price Term Code',
            template_name: 'Delivery Claim Template.xlsx',
            pickUpListUpload: 'Delivery Claim Upload',
            inconsistent_basic_price_download: 'Inconsistent Basic Price Download',
            basic_price_upload: 'Basic Price Upload',
            inconsistent_offline_basic_price: 'Inconsistent Offline Basic Price',
            material_code: 'Material Code',
            reg_no: 'Reg No.',
            eu_invoice_no: 'Invoice No.',
            eu_invoice_status: 'Invoice Status',
            eu_country_name: 'Country Name',
            eu_country_code: 'Country Code',
            eu_group: 'Group',
            eu_financial_company: 'Financial Company',
            eu_interior_price: 'Interior Price',
            eu_invoice_price: 'Invoice Price',
            eu_unit_price: 'Unit Price(Incl.VAT)',
            eu_cancel_date: 'Cancel Date',
            eu_gate_out_time: 'Gate Out Time',
            eu_transportation_costs: 'Transportation Costs',
            eu_delivery_time: 'Delivery Time',
            eu_stock_status: 'Stock Status',
            eu_related_retail_order: 'Related Retail Order',
            eu_related_wholesales_order: 'Related Wholesales Order',
            eu_fleet_type: 'Fleet Type',
            eu_order_active_date: 'Order Activated Date',
            eu_contract_date: 'Contract Date',
            eu_account_record_type: 'Related Account Record Type',
            eu_incentive_amount: 'Incentive Amount',
            reg_status: 'Reg Status',
        },
    },
    rrmgmt: {
        zhCN: {
            reg: '注册号',
            reg_date: '注册日期',
            reg_cancel_date: '注册取消日期',
            vin: 'VIN',
            order_type: '订单类型',
            channel: 'Channel',
            price_term: 'Price Term',
            credit_company: '信贷公司',
            payment_type: '付款类型',
            coupon_no: '优惠券',
            quantity: '数量',
            basic_price: '基本价格',
            paint: '油漆',
            delivery: '交付',
            total_price: '总价',
            basic_margin: '基础返利',
            stds_margin: 'Stds Margin',
            vrb: 'Quarterly Bonus',
            cash_olo_finance: 'Cash ILO Finance',
            ev_test_drive: 'Test Drive Support',
            dealer_fleet: 'Dealer Fleet',
            demo: 'Demo',
            courtesy_car: 'Courtesy Car',
            motability: 'Motability',
            affinity: 'Affinity',
            adjustment: 'Adjustment',
            adjustment_import: '导入Adjustment',
            adjustment_reason: 'Adjustment Reason',
            appeal_options: 'Appeal Options',
            total_support: 'Total Support',
            mgmfs_invoice: 'MGMFS Invoice',
            delivery_refund: 'Delivery Refund',
            promotion_total_support: 'Promotion Total Support',
            balance_due_t: '返利金额（不含税）',
            balance_due_z: '返利总金额（不含税）',
            grand_total_t: '返利金额（含税）',
            grand_total_z: '返利总金额（含税）',
            unpaid_vat: '未支付（含税）',
            deduct: '扣除',
            deduct_reason: '扣除原因',
            error_msg: '错误信息',
            other_msg: '申诉回复',
            number_order: '序号',
            rebate_no: '返利编号',
            dealer_code: '经销商代码',
            dealer_name: '经销商名称',
            dealer_group: '经销商组',
            dealer_status: '经销商状态',
            settlement_period: '结算周期',
            advance_payment: '预付金额(不含税)',
            advance_payment_vat: '预付金额(含税)',
            balance_due: '返利总金额(不含税)',
            grand_total: '本次支付 (不含税)',
            grand_total_vat: '本次支付 (含税)',
            difference: '差额 (不含税)',
            pay_status: '支付状态',
            pay_time: '支付时间',
            dealer_feedback_status: '经销商反馈状态',
            dealer_confirm_status: '经销商确认状态',
            appeals: '申诉',
            appeal_deadline: '申诉截止日期',
            is_submitSAP: '是否提交SAP',
            exportSAP: 'SAP导出',
            is_exportSAP: '是否导出SAP',
            confirm_unpaid: '确认且未支付',
            last_approver: '最新审批人',
            last_approve_date: '最新审批时间',
            checker: '确认人',
            confirm_btn: '确认',
            check_time: '确认时间',
            create_time: '创建时间',
            monthly: '月度',
            total_rebate_amount: '返利总金额',
            approver: '审批人',
            approve_time: '审批时间',
            approval_status: '审批状态',
            export_status: '导出状态',
            operation: '操作',
            time_limit: '时间限制',
            submit_sap: '提交 SAP',
            batch_edit_pay_status: '批量编辑付款状态',
            change_pay_status: '更改付款状态',
            quarterly_recalibration: '返利计算',
            appeal_recalibration: '申诉返利计算',
            rebate_calculate: '返利计算',
            please_confirm_that_the_bulk_adjustment_payment_status: '请确认批量调整付款状态：',
            please_confirm_that_the_payment_has_been_completed: '请确认付款已完成',
            click_to_pass: '单击 "通过 "以通过所有选定项目。',
            click_to_approved: '单击 "批准 "以通过所有选定项目。',
            model_version: '型号/版本',
            colour: '颜色',
            color: '颜色',
            order_date: '下单日期',
            summary: '汇总',
            net: '净金额',
            vat: '税额',
            total: '合计',
            sales_target: '销售目标',
            actual_sales: '实际销量',
            achieving_rate: '目标达成率',
            approval_opinion: '审批意见',
            approval_remark: '上级审批备注',
            remark: '备注',
            batch_pass: '批量通过',
            approval_history: '审批历史',
            approval_result: '审批结果',
            invoice_date: ' 发票日期',
            invoice_amount: '发票金额',
            invoice_amount_vat: '发票金额（含税）',
            adjust_sales: '调整',
            fleet: 'Fleet',
            date: '时间',
            month: '月',
            target: '目标',
            // sales: '销售额',
            sales: '销量',
            sales_volume: '销量',
            regs: 'Regs',
            total_target: '总目标',
            total_sales: '总销售额',
            select_quarter: '选择季度',
            appeal_reason: '申诉原因',
            select_quarter_rek: '请勾选需要计算的季度时间段或者KPI 政策',
            select_quarter_down: '*下拉框里的内容是当前开始进行结算的数据',
            reasons_for_appeal: '申诉理由',
            d_reasons_for_appeal: '经销商申诉原因',
            rebate_name: '返利名称',
            unconfirm: 'Unconfirm (NET)',
            unpayment: 'Unpayment (NET)',
            unpayment_vat: 'Confirmed Unpaid (Incl.VAT)',
            confirm: 'Confirm (NET)',
            paid: 'Paid (NET)',
            paid_vat: 'Paid (Incl.VAT)',
            sales_are_correct: '确认实际销量',
            target_are_correct: '确认销量目录',
            confirm_tip: '请确认该季度销量是否正确，一旦确认将不再变更销量统计.',
            clear_tip: '请确认所选行的确认状态将被清除。',
            pass_tips: '单击“通过”可通过所有选定项目。',
            payment_per_unit: '单位付款',
            ladder_bonus: 'Ladder Bonus (NET)',
            ladder_fleet: 'Dealer Fleet (NET)',
            average_bonus: 'Average Bonus (NET)',
            confirm_net: 'Confirm (NET)',
            m1_advance_payment: 'M1 Advance Payment (NET)',
            m1_advance_payment_vat: 'M1 Advance Payment (Incl.VAT)',
            m2_advance_payment: 'M2 Advance Payment (NET)',
            m2_advance_payment_vat: 'M2 Advance Payment (Incl.VAT)',
            payment_amount_net: 'Payment Amount (NET)',
            mumber_of_payment: 'Number of Payment',
            target_sales_adj: 'Target/Sales Adj',
            submit_date: 'Submit Date',
            batch_apply_payment: '批量申请支付',
            batch_apply_payment_tip: '请确认提交所选结果！',
            comment: '意见',
            payment_approval: '付款审批',
            region: '地区',
            batch_import_of_adjustments: '调整项批量导入',
            adjustment_item: '调整项',
            please_download_invoice: '发票下载',
            current_total_payment_amount: '当前总付款金额',
            paid_date: '支付日期',
            des_reminders_for_dealer: '已在审核中，审核后才能操作数据',
            des_reminders_for_dealer_no_chance: '申诉次数已用完',
            balance_due_difference: '返利金额差异：',
            computation_reminder: 'Price Term 和 Order Type 必填',
            incl_vat: '含税总计',
            tip_adjustment: '自定义行adjustment列或Adjustment Reason必填',
            achievement_thresholds: '成就阈值',
            total_confirmed: 'Total Confirmed (Incl.VAT)',
            confirm_status: '确认状态',
            confirm_completion: '确认完成',
            appeal_status: '申诉状态',
            filter: '过滤',
            clear: '清除',
            confirmed_amount: '确认金额',
            confirmation_date: '确认日期',
            sales_date: '销售日期',
            order_cancel_date: '订单取消日期',
            whole_sale_margin: 'Whole Sale Margin',
            retail_margin: 'Retail Margin',
            bouns: 'Bouns',
            de_for_nonCompliance: 'Deduction for non_compliance',
            dt_code: 'DT',
            dt_codes: 'DT Code',
            eu_rebate_no: '返利编号',
            eu_rebate_name: '返利名称',
            eu_customer_code: '客户代码',
            eu_customer_name: '客户名称',
            eu_customer_status: '客户状态',
            eu_accrual_no: 'Accrual No.',
            eu_advance_payment: 'Advance Payment',
            eu_total_support_net: 'Total Support(NET)',
            eu_total_support_vat: 'Total Support(Incl.VAT)',
            eu_unpaid: 'Unpaid',
            eu_paid: 'Paid',
            eu_policy_name: 'Policy Portfolio Name',
            eu_material_code: 'Material Code',
            eu_delivery_date: 'Delivery Date',
            eu_wholesales_order_type: 'Wholesales Order Type',
            eu_msrp: 'MSRP',
            eu_msrp_vat: 'MSRP(w/o VAT)',
            eu_msrp_vatr: 'MSRP(Incl. VAT)',
            eu_msrp_net: 'Net MSRP',
            eu_list_price: 'List Price',
            eu_color_price: 'Color Price',
            eu_color_price_vat: 'Color Price(Incl.VAT)',
            eu_kpi_name: 'KPI Policy Name',
            eu_vat_rate: 'VAT Rate',
            eu_last_approver: 'Last Processor',
            eu_latest_time: 'Latest Update Time',
            eu_retail_volume: 'Retail Volume',
            eu_wholesales_volume: 'Wholesales Volume',
            eu_rebate_deduction_no: 'Rebate No/Deduction No',
            eu_rebate_deduction_name: 'Rebate Name/Deduction Name',
            eu_remark: '备注',
            eu_remark_return: '审批意见必填！',
            eu_pay_tip: 'Please confirm that the payment has been completed. The status can not be changed after this operation.',
            eu_business_type: 'Business Type',
            eu_trim: 'Trim',
            eu_rebate_type: 'Rebate Type',
            eu_calculation_time: 'Calculation Time',
            eu_payment_time: 'Payment Time',
            eu_bonus: 'Bonus',
            eu_bonus_vat: 'Bonus(VAT Incl.)',
            eu_wholesales_orders: 'Wholesales Orders',
            eu_retail_orders: 'Retail Orders',
            eu_vehicle_material_code: '物料号',
            eu_order_cancelled_code: '订单取消日期',
            eu_process_status: '进度状态',
            eu_process_date: '进度日期',
            eu_registration_code: 'Registration Code',
            eu_registration_Date: 'Registration Date',
            eu_key: 'Key',
            eu_ttl: 'TTL',
        },
        enUS: {
            date: 'Date',
            reg: 'Reg',
            reg_date: 'Reg Date',
            reg_cancel_date: 'Reg Cancel Date',
            vin: 'VIN',
            order_type: 'Order Type',
            channel: 'Channel',
            price_term: 'Price Term',
            credit_company: 'Credit Company',
            payment_type: 'Payment Type',
            coupon_no: 'Coupon No',
            quantity: 'Quantity',
            basic_price: 'Basic Price',
            paint: 'Paint',
            delivery: 'Delivery',
            total_price: 'Total Price',
            basic_margin: 'Basic Margin',
            stds_margin: 'Stds Margin',
            vrb: 'Quarterly Bonus',
            cash_olo_finance: 'Cash ILO Finance',
            ev_test_drive: 'Test Drive Support',
            dealer_fleet: 'Dealer Fleet',
            demo: 'Demo',
            courtesy_car: 'Courtesy Car',
            motability: 'Motability',
            affinity: 'Affinity',
            adjustment: 'Adjustment',
            adjustment_import: 'Adjustment Import',
            adjustment_reason: 'Adjustment Reason',
            appeal_options: 'Appeal Options',
            total_support: 'Total Support',
            mgmfs_invoice: 'MGMFS Invoice',
            balance_due_t: 'Balance Due',
            delivery_refund: 'Delivery Refund',
            promotion_total_support: 'Promotion Total Support',
            balance_due_z: 'Total Amount(NET)',
            grand_total_t: 'Grand Total',
            grand_total_z: 'Total Amount(Incl.VAT)',
            unpaid_vat: 'Unpaid(Incl.VAT)',
            deduct: 'Deduct',
            deduct_reason: 'Deduct Reason',
            error_msg: 'Error Message',
            other_msg: 'Appeal Reply',
            number_order: 'NO.',
            rebate_no: 'Rebate Reference',
            dealer_code: 'Dealer Code',
            dealer_name: 'Dealer Name',
            dealer_group: 'Dealer Group',
            dealer_status: 'Dealer Status',
            settlement_period: 'Settlement Period',
            advance_payment: 'Advance Payment (NET)',
            advance_payment_vat: 'Advance Payment (Incl.VAT)',
            balance_due: 'Balance Due (NET)',
            grand_total: 'Grand Total (NET)',
            grand_total_vat: 'Grand Total (Incl.VAT)',
            difference: 'Difference (NET)',
            pay_status: 'Pay Status',
            pay_time: 'QTLY PMT',
            dealer_feedback_status: 'Dealer feedback Status',
            dealer_confirm_status: 'Dealer Confirm Status',
            appeals: 'Appeals',
            appeal_deadline: 'Appeal Deadline',
            is_submitSAP: 'Is SubmitSAP',
            exportSAP: 'SAP Export',
            is_exportSAP: 'Is ExportSAP',
            confirm_unpaid: 'Confirm&Unpaid',
            last_approver: 'Last Approver',
            last_approve_date: 'Last Approve Date',
            checker: 'Checker',
            confirm_btn: 'Confirm',
            check_time: 'Check Time',
            create_time: 'Create Time',
            monthly: 'Monthly',
            total_rebate_amount: 'Total Rebate Amount',
            approver: 'Approver',
            approve_time: 'Approvel Time',
            approval_status: 'Approval Status',
            export_status: 'Export Status',
            operation: 'Operation',
            time_limit: 'Time Limit',
            submit_sap: 'Submit SAP',
            batch_edit_pay_status: 'Batch Edit Pay Status',
            change_pay_status: 'Change Pay Status',
            quarterly_recalibration: 'Calculation',
            appeal_recalibration: 'Appeal Calculation',
            rebate_calculate: 'Rebate Calculation',
            please_confirm_that_the_bulk_adjustment_payment_status:
                'Please confirm that the bulk adjustment payment status：',
            please_confirm_that_the_payment_has_been_completed:
                'Please confirm that the payment has been completed',
            click_to_pass: 'Click ‘Pass’ to pass all of selected items.',
            click_to_approved: 'Click ‘Approved’ to pass all of selected items.',
            model_version: 'Model / Version',
            colour: 'Colour',
            color: 'Color',
            order_date: 'Order Date',
            summary: 'Summary',
            net: 'NET',
            vat: 'VAT',
            total: 'TOTAL',
            sales_target: 'Sales Target',
            actual_sales: 'Actual Sales',
            achieving_rate: 'Achieving Rate',
            approval_opinion: 'Approval opinion',
            approval_remark: 'Remarks for superior approval',
            remark: 'Remark:',
            batch_pass: 'Batch Pass',
            approval_history: 'Approval History',
            approval_result: 'Approval Result',
            invoice_date: ' Invoice Date',
            invoice_amount: 'Invoice Amount',
            invoice_amount_vat: 'Invoice Amount(Incl.VAT)',
            adjust_sales: 'Adjust Sales',
            fleet: 'Fleet',
            month: 'Month',
            target: 'Target',
            sales: 'Sales',
            sales_volume: 'Sales Volume',
            regs: 'Regs',
            total_target: 'Total Target',
            total_sales: 'Total sales',
            select_quarter: 'Select Quarter',
            appeal_reason: 'Appeal Reason',
            select_quarter_rek:
                'Please select the periods or KPI policy that you want to calculate.',
            select_quarter_down:
                '*The items in the drop-down box are the data for the currently starting billing process',
            reasons_for_appeal: 'Reasons For Appeal',
            d_reasons_for_appeal: 'Reason For Dealer Appeal',
            rebate_name: 'Rebate Name',
            unconfirm: 'Unconfirm (NET)',
            unpayment: 'Unpayment (NET)',
            unpayment_vat: 'Confirmed Unpaid (Incl.VAT)',
            confirm: 'Confirm (NET)',
            paid: 'Paid (NET)',
            paid_vat: 'Paid (Incl.VAT)',
            sales_are_correct: 'Sales Are Correct',
            target_are_correct: 'Target Are Correct',
            confirm_tip:
                'Please confirm the sales order quantity in this quarter. After confirmed, the sales data (target and sales) cannot be change.',
            clear_tip:
                'Please confirm that the confirmation status of the selected row will be cleared.',
            pass_tips: 'Click \'Pass\' to pass all of selected items.',
            payment_per_unit: 'Payment Per Unit',
            ladder_bonus: 'Ladder Bonus (NET)',
            ladder_fleet: 'Dealer Fleet (NET)',
            average_bonus: 'Average Bonus (NET)',
            confirm_net: 'Confirm (NET)',
            m1_advance_payment: 'M1 Advance Payment (NET)',
            m1_advance_payment_vat: 'M1 Advance Payment (Incl.VAT)',
            m2_advance_payment: 'M2 Advance Payment (NET)',
            m2_advance_payment_vat: 'M2 Advance Payment (Incl.VAT)',
            payment_amount_net: 'Payment Amount (NET)',
            mumber_of_payment: 'Number of Payment',
            target_sales_adj: 'Target/Sales Adj',
            submit_date: 'Submit Date',
            batch_apply_payment: 'Batch Apply Payment',
            batch_apply_payment_tip: 'Please confirm the submission of the selected results!',
            comment: 'Comment',
            payment_approval: 'Payment Approval',
            region: 'Region',
            batch_import_of_adjustments: 'Batch Import Of Adjustments',
            adjustment_item: 'Adjustment Item',
            please_download_invoice: 'Invoice Download',
            current_total_payment_amount: 'Current Total Payment Amount',
            paid_date: 'Paid Date',
            des_reminders_for_dealer: 'In processing, unable to submit',
            des_reminders_for_dealer_no_chance: 'There is no chance to appeal',
            balance_due_difference: 'new balance due - old balance due:',
            computation_reminder: 'Price Term and Order Type is required',
            incl_vat: 'Payment Amount(Incl.VAT)',
            tip_adjustment: 'Custom row adjustment column or Adjustment Reason required',
            achievement_thresholds: 'Achievement Thresholds',
            total_confirmed: 'Total Confirmed (Incl.VAT)',
            confirm_status: 'Confirm Status',
            confirm_completion: 'Confirm Completion',
            appeal_status: 'Appeal Status',
            appeal_update: 'Appeal Update',
            filter: 'filter',
            clear: 'clear',
            confirmed_amount: 'Confirmed Amount(Incl.VAT)',
            confirmation_date: 'Confirmation Date',
            sales_date: 'Sales Date',
            order_cancel_date: 'Order Cancel Date',
            whole_sale_margin: 'Whole Sale Margin',
            retail_margin: 'Retail Margin',
            bouns: 'Bouns',
            de_for_nonCompliance: 'Deduction for non_compliance',
            dt_code: 'DT',
            dt_codes: 'DT Code',
            eu_rebate_no: 'Rebate No.',
            eu_rebate_name: 'Rebate Name',
            eu_customer_code: 'Customer Code',
            eu_customer_name: 'Customer Name',
            eu_customer_status: 'Customer Status',
            eu_accrual_no: 'Accrual No.',
            eu_advance_payment: 'Advance Payment',
            eu_total_support_net: 'Total Support(NET)',
            eu_total_support_vat: 'Total Support(Incl.VAT)',
            eu_unpaid: 'Unpaid',
            eu_paid: 'Paid',
            eu_policy_name: 'Policy Portfolio Name',
            eu_material_code: 'Material Code',
            eu_delivery_date: 'Delivery Date',
            eu_wholesales_order_type: 'Wholesales Order Type',
            eu_msrp: 'MSRP',
            eu_msrp_vat: 'MSRP(w/o VAT)',
            eu_msrp_vatr: 'MSRP(Incl.VAT)',
            eu_msrp_net: 'Net MSRP',
            eu_list_price: 'List Price',
            eu_color_price: 'Color Price',
            eu_color_price_vat: 'Color Price(Incl.VAT)',
            eu_kpi_name: 'KPI Policy Name',
            eu_vat_rate: 'VAT rate',
            eu_last_approver: 'Last Processor',
            eu_latest_time: 'Latest Update Time',
            eu_retail_volume: 'Retail Volume',
            eu_wholesales_volume: 'Wholesales Volume',
            eu_rebate_deduction_no: 'Rebate No/Deduction No',
            eu_rebate_deduction_name: 'Rebate Name/Deduction Name',
            eu_remark: 'Remark',
            eu_remark_return: 'Approval Opinion is required！',
            eu_pay_tip: 'Please confirm that the payment has been completed. The status can not be changed after this operation.',
            eu_business_type: 'Business Type',
            eu_trim: 'Trim',
            eu_rebate_type: 'Rebate Type',
            eu_calculation_time: 'Calculation Time',
            eu_payment_time: 'Payment Time',
            eu_bonus: 'Bonus',
            eu_bonus_vat: 'Bonus(VAT Incl.)',
            eu_wholesales_orders: 'Wholesales Orders',
            eu_retail_orders: 'Retail Orders',
            eu_vehicle_material_code: 'Vehicle Material Code',
            eu_order_cancelled_code: 'Order Cancelled Date',
            eu_process_status: 'Process Status',
            eu_process_date: 'Process Time',
            eu_registration_code: 'Registration Code',
            eu_registration_Date: 'Registration Date',
            eu_key: 'Key',
            eu_ttl: 'TTL',
        },
    },
    rrapproval: {
        zhCN: {
            number_order: '序号',
            rebate_no: '返利编号',
            dealer_code: '经销商代码',
            dealer_name: '经销商名称',
            dealer_status: '经销商状态',
            settlement_period: '结算周期',
            advance_payment: '预付金额(不含税)',
            balance_due: '返利总金额(不含税)',
            grand_total: '本次支付 (不含税)',
            pay_status: '支付状态',
            dealer_feedback_status: '经销商反馈状态',
            is_submitSAP: '是否提交SAP',
            last_approver: '最新审批人',
            last_approve_date: '最新审批时间',
            checker: '确认人',
            check_time: '确认时间',
            create_time: '创建时间',
            monthly: '月度',
            total_rebate_amount: '返利总金额',
            approver: '审批人',
            approve_time: '审批时间',
            approval_status: '审批状态',
            operation: '操作',
            time_limit: '时间限制',
            batch_pass: '批量通过',
            start_createtime: '创建开始时间',
            end_createtime: '创建结束时间',
            approved_time: '审核时间',
        },
        enUS: {
            number_order: 'NO.',
            rebate_no: 'Rebate Reference',
            dealer_code: 'Dealer Code',
            dealer_name: 'Dealer Name',
            dealer_status: 'Dealer Status',
            settlement_period: 'Settlement Period',
            advance_payment: 'Advance Payment (NET)',
            balance_due: 'Balance Due (NET)',
            grand_total: 'Grand Total (NET)',
            pay_status: 'Pay Status',
            dealer_feedback_status: 'Dealer feedback Status',
            is_submitSAP: 'Is SubmitSAP',
            last_approver: 'Last Approver',
            last_approve_date: 'Last Approve Date',
            checker: 'Checker',
            check_time: 'Check Time',
            create_time: 'Create Time',
            monthly: 'Monthly',
            total_rebate_amount: 'Total Rebate Amount',
            approver: 'Approver',
            approve_time: 'Approve Time',
            approval_status: 'Approval Status',
            operation: 'Operation',
            time_limit: 'Time Limit',
            batch_pass: 'Batch Pass',
            start_createtime: 'CreateTime Start Date',
            end_createtime: 'CreateTime End Date',
            approved_time: 'Approved Time',
        },
    },
};
